/* Reset some default styles */
body, ul {
    margin: 0;
    padding: 0;
  }
  
  /* Style the header and navigation bar */
  header {
    background-color: rgb(225, 141, 23);
    color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .logo {
    font-size: 24px;
  }
  
  .menu {
    list-style: none;
    display: flex;
  }
  
  .menu li {
    margin-right: 20px;
  }
  
  .menu a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .menu a:hover {
    color: #ff5733;
  }
  
  /* Style the burger button */
  .menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    
  }
  
  .bar {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
  }

  .headerStyles {
    background-color: rgb(225, 141, 23);
    color: white;
    padding: 10px; 
    font-family: "Monoton", sans-serif;
    text-align: center;
    word-spacing: 6px;
    animation: flash 3s infinite;
    align-items: center;
    display: flex;
    font-size: 35px;
    border: none;
    cursor: pointer;
  }
  
  /* desktop & tablet config */
@media (min-width: 770px) {
  .headerStyles {
    font-size: 35px;
  }
  .menu-font-size {
    font-size: 20px;
  }

  .pluto-pic {
    width: 30px !important; /* Adjust the width for desktop */
    height: 30px !important; /* Adjust the height for desktop */
    margin: 0 auto;
    display: block;
    display: inline-block;
    position: relative;
    background: rgb(225, 141, 23);
    border-Top: none;
    border-Bottom: none;
    border-Radius: 20px;
    border-Color: white;
    box-Shadow: 0 0 10px rgba(255, 255, 255, 1);
  }

  .menu.active {
    display: flex;
  }

  .menu-toggle {
    display: flex;
  }
}

/* mobile */
@media (max-width: 768px) {
  .menu {
    font-size: 20px;
  }

  .headerStyles {
    font-size: 28px;
    display: flex;
    align-items: center;
  }

  .menu.active {
    display: flex;
  }

  .menu-toggle {
    display: flex;
  }

  .menu-font-size {
    font-size: 16px;
  }

  .pluto-pic {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    display: block;
    display: inline-block;
    position: relative;
    background: rgb(225, 141, 23);
    border-Top: none;
    border-Bottom: none;
    border-Radius: 20px;
    border-Color: white;
    box-Shadow: 0 0 10px rgba(255, 255, 255, 1);
  }
}

  
  @import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

/* Your existing CSS styles go here */
.text-title {
  font-family: 'Monoton', sans-serif; /* Apply the imported font to your styles */
}

@keyframes flash {
  0%, 20% {
    color: rgb(225, 141, 23); /* Change the text color to red at 0% and 50% of the animation */
  }
  20%, 40% {
    color: rgb(255, 255, 255); /* Change the text color to blue at 25% and 75% of the animation */
  }
  40%, 60% {
    color: rgb(225, 141, 23); /* Change the text color to red at 0% and 50% of the animation */
  }
  60%, 80% {
    color: rgb(255, 255, 255); /* Change the text color to blue at 25% and 75% of the animation */
  }
}
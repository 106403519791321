
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-container {
  width: 100px; /* Set a fixed width as needed */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide text that overflows the container */
  text-overflow: ellipsis; /* Show an ellipsis (...) when the text overflows */
  text-align: left; /* Align text to the left */
}

#moving-text {
  display: inline-block;
  white-space: nowrap;
}

.cursor {
  display: inline-block;
  width: 1ch;
  animation: flicker 2s infinite;
  margin-bottom: 2px;
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.flash-text {
  display: inline-flex;
  font-size: 24px;
}

.letter {
  animation: flash 2s infinite;
  opacity: 0;
}

@keyframes flash {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Monoton&family=Roboto:wght@300&family=Tomorrow:wght@300&family=Tourney:wght@100&family=Wallpoet&display=swap');

/* Your existing CSS styles go here */
.text-title {
  font-family: 'Monoton', sans-serif; /* Apply the imported font to your styles */
}

/* Your existing CSS styles go here */
.text-loop {
  font-family: 'Tomorrow', sans-serif; 
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  top: 0;
  right: 10;
  flex-grow: 1;
  color: white;
  font-size: 30px;
  text-align: left;
  background: black;
  text-align: center;
  display: inline-block;
  word-spacing: 3px;
  height: 100px;
  padding: 20px;
  border: 1px solid black; 
}

.type-fonts {
  font-family: 'Tomorrow', sans-serif; 
  font-size: 14px;
  font-weight: bold;
}

@media (max-width: 600px) {

  .text-loop {
    font-size: 18px;
  }
}

@keyframes flash {
  0%, 50% {
    color: rgb(225, 141, 23); 
  }
  /* 10%, 40% {
    color: rgb(255, 255, 255); 
  }
  40%, 60% {
    color: #ffb3ff; 
  } */
  50%, 100% {
    color: rgb(255, 255, 255); 
  }
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: sans-serif;
}

.image-item {
  width: 25%; /* 4 items per row */
  text-align: center;
  display: flex; /* Use flexbox for vertical alignment */
  flex-direction: column; /* Stack image and text vertically */
  align-items: center; /* Center items horizontally */
}

.image-item img {
  max-width: 50%;
}

/* Add other CSS styles as needed */

.general-css {
  border-color:black;
  padding-bottom: '30px';
  padding-top: '30px';
}

.disclaimer-modal {
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center; /* Vertical center */
  justify-content: center; /* Horizontal center */
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds a shadow effect */
  width: 80%; /* Adjust width as needed */
  max-width: 500px; /* Maximum width */
  border: 1px solid white;
  justify-content: space-between;
  font-size: 14px;
}

.modal-content button {
  background-color: white;
  color: black;
  border: 1px solid rgb(0, 0, 0);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
  justify-content: space-between;
}

.login-button {
  color: white;
  margin: 10px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: black;
  align-items: center;
  cursor: pointer;
  padding-right: 5px;
  margin-left: 20px;
  font-size: 16px;
}
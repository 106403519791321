/* const containerStyle = {
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh', 
  };

  const formStyle = {
    padding: '10px',
    border: '1px solid white',
    borderRadius: '5px',
    width: '400px',
    color: "grey",
    margin: '20px'
  };

  const inputStyle = {
    width: '100%', // Input boxes 100% width within the form
    height: '2.5rem', // 1.5 times higher input boxes
    marginBottom: '20px', // Space between rows x2 larger
    borderRadius: '5px', // Rounded edges for inputs
    fontFamily: 'sans-serif',
    paddingLeft: '10px'
  }; */

  .button-container button {
    display: block;
    margin-bottom: 5px;
  }

  .button-style {
    color: white;
    margin: 10px;
    border: 1px solid #ffb3ff;
    border-radius: 5px;
    background-color: black;
    align-items: center;
    cursor: pointer;
    padding-right: 5px;
    margin-left: 20px;
  }
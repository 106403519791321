html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.places-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 300px;
}

.map-container {
  width: 100%;
  height: 75vh;
  display: inline-block;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
  margin-top: 35%;
  border-radius: 4px; 
}

/* styles.css */

.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 10px;
  font-family: 'Tomorrow', sans-serif; 
}

.event-type-select {
  background-color: black;
  color: white;
  border: 1px solid white;
  margin-left: 10px;
  cursor: pointer;
  font-family: 'Tomorrow', sans-serif; 
}

.filter-label {
  color: white;
  font-family: 'Tomorrow', sans-serif; 
}

.info-window {
  /* background-color: black;
  color: white; */
  /* padding: 1px; 
  border-radius: 1px; */
  width: auto; /* Width of the box */
  font-size: 12px;
  border: 0.25px solid white; /* Thinner border */
  /* Optional: Fixed height */
  height: auto; 
  line-height: 1; /* Adjust line height for better readability */
  text-align: center;
  font-family: 'Tomorrow', sans-serif; 
}

.event-name {
  font-weight: bold;
}

.date-picker-container {
  justify-content: space-around;
  padding: 5px;
  background-color: black;
}

.date-picker-container button {
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: black;
  cursor: pointer;
  color: white;
}

.date-picker-container button.selected {
  background-color: #ffb3ff; /* Highlight the selected date */
}

.text-box-above-header2 {
  color: white;
  display: flex;
  justify-content: space-between;
  background-color: black;
  font-family: 'Tomorrow', sans-serif;

}
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes marquee {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
  }
  
  .marquee {
    overflow: hidden;
    white-space: nowrap;
  }
  
  .marquee span {
    display: inline-block;
    padding-left: 2%;
    animation: marquee 18s linear infinite;
  }
  
  
  #moving-text {
    display: inline-block;
    white-space: nowrap;
  }
  
  .cursor {
    display: inline-block;
    width: 1ch;
    animation: flicker 0.5s infinite;
    margin-bottom: 2px;
  }
  
  @keyframes flicker {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .event-info-home {
    margin: 0;
    place-items: center;
    padding: 2px;
    font-family: 'Tomorrow', sans-serif;
  }
  
  .event-container-home {
    /*border: 0.5px dashed #928787;*/
    color: black;
    display: flex;
    justify-content: space-between;
  }
  
  
  .event-description {
    font-style: italic;
  }

  
  .event-type-image:hover {
    cursor: pointer; 
  }

  .home-styles {
    background-color: white;
  }

  .event-name-home {
    flex: 2;
  }  

  .event-column-home {
    flex: 1;
    padding: 5px;
    align-items: center;
    display: flex;
  }

/* mobile config */
@media (max-width: 600px) {

  body {
    font-size: 10px;
  }

  .sticky-header {
    position: sticky;
    top: 60px; 
    font-weight: bold;
    z-index: 100; 
    justify-content: space-between;
    background-color: white;
    font-family: 'Tomorrow', sans-serif;
  }

  .event-column-home {
    flex-grow: 1; /* Flex items grow to fill space */
    min-width: 10px; 
    font-size: 10px;
    flex: 1;
    padding: 5px;
    align-items: center;
    }
    
  .event-name-home {
    flex: 2;
  }  

  .event-city-home {
    flex: 2;
  }  
  
  .truncate {
      
      white-space: normal; /*nowrap*/
      overflow: hidden;
      text-overflow: ellipsis;
      
    }
  .listing-events {
    font-size: 12px;
    justify-content: space-between;
  }    
  }

  .event-type-image{
    max-width: 70%;
  }

  /* desktop config */
  @media (min-width: 1441px) {

    .sticky-header {
      position: sticky;
      top: 60px; 
      font-weight: bold;
      z-index: 100; 
      justify-content: space-between;
      font-size: 20px;
      background-color: white;
      font-family: 'Tomorrow', sans-serif;
    }

    .event-column-home {
     flex: 2;
     padding: 0px;
     align-items: center;
   }
   
   .truncate {
      
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .listing-events {
    font-size: 14px;
    margin-right: 20px;
    justify-content: space-between;
  }  
  .event-type-image{
    max-width: 15%;
  }
  }

/* Tablets / Small Desktops/Laptops */
@media (min-width: 601px) and (max-width: 1440px) {
 
  .sticky-header {
    position: sticky;
    top: 60px; 
    font-weight: bold;
    z-index: 100; 
    justify-content: space-between;
    padding: 1px;
    font-size: 20px;
    background-color: white;
    font-family: 'Tomorrow', sans-serif;
  }
    
  .event-name-home {
    flex: 2; 
    }  

  .listing-events {
    font-size: 14px;
    margin-right: 20px;
    justify-content: space-between;
    background-color: black;
    position: sticky;
    top: 50px; 
    z-index: 100; 
  }  
  .event-type-image{
    max-width: 30%;
  }
}


.text-box-above-header {
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background-color: black;
  font-family: 'Tomorrow', sans-serif;
}

.all-event-filter {
  background-color: black;
  color: white;
  font-size: 14px;
  padding: 1px;
  margin: 3px;
  cursor: pointer;
  font-family: 'Tomorrow', sans-serif; 
}

